.website-style {
  nav {
    background-color: transparent;
    height: 100px;
    transition: ease height 300ms;
  
    .navbar-collapse {
      justify-content: space-evenly;
  
      a {
        margin-right: 5px;
        color: #fff !important;
        text-decoration: none;
        font-weight: 700;
  
        &:hover,
        &:focus-visible {
          color: #36b4e5 !important;
        }
      }
    }
  
    .navbar-brand {
      img {
        margin-left: 80px;
        height: 55px;
      }
    }
  
    .nav-icons {
      display: flex;
      justify-content: center;
  
      .social-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50px;
  
        .facebook-icon:hover {
          color: #3b5998 !important;
        }
  
        .linkedin-icon:hover {
          color: #0e76a8 !important;
        }
  
        .instagram-icon:hover {
          color: #dd2a7b !important;
        }
  
        .whatsapp-icon:hover {
          color: #25d366 !important;
        }
  
        :hover {
          cursor: pointer;
        }
      }
    }
  
    .navbar-toggler {
      background-color: #36b4e5;
    }
  
    .navbar-toggler:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
  }
  
  .navbar-black {
    background-color: #212529;
    height: 70px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.27);
  
    .social-circle {
      .facebook-icon {
        color: #3b5998 !important;
      }
  
      .linkedin-icon {
        color: #0e76a8 !important;
      }
  
      .instagram-icon {
        color: #dd2a7b !important;
      }
  
      .whatsapp-icon {
        color: #25d366 !important;
      }
    }
  }
  
  @media (max-width: 991px) {
    nav {
      .navbar-brand img {
        margin-left: 0px;
        height: 50px;
      }
  
      .nav-icons {
        padding: 15px 0;
        display: flex;
        justify-content: center;
        flex-direction: row;
  
        .social-circle:nth-child(2) {
          margin: 0 5px;
        }
  
        .social-circle {
          display: flex;
          justify-content: center;
          align-items: center;
  
          .facebook-icon {
            color: #3b5998 !important;
          }
  
          .linkedin-icon {
            color: #0e76a8 !important;
          }
  
          .instagram-icon {
            color: #dd2a7b !important;
          }
  
          .whatsapp-icon {
            color: #25d366 !important;
          }
        }
      }
    }
    .navbar-collapse {
      align-items: center;
      border-radius: 0.25rem;
      margin-top: 10px;
  
      a {
        margin-right: 0 !important;
      }
  
      .navbar-nav:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        margin: auto;
        box-shadow: 0 0 20px 2px #0000006b;
      }
  
      .navbar-nav:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        margin: auto;
        margin-top: -1px;
        box-shadow: 0px 13px 20px 1px #0000006b;
      }
  
      .navbar-nav {
        align-items: center;
        max-width: 500px;
        background-color: #212529;
  
        .nav-link {
          text-align: center;
          width: 80%;
          padding: 15px 0;
  
          &:nth-child(-n + 3) {
            border-bottom: 1px solid #4b5155;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .hamburger {
      display: none;
    }
  }

}

// Hamburger button style
.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #ecf0f1;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 32px;
}
.hamburger:hover {
  cursor: pointer;
}
#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}
#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}
#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}
