.login-row {
  display: flex;
  min-height: calc(100vh);
.input-group{
  width: 100%;
}
  label {
    font-size: 18px;
  }

  .left-card {
    background-color: #25262f;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 50px 0px #00000073;

    img {
      height: 180px;
    }
  }

  .right-card {
    margin: auto;
    max-width: 550px;
  }
}
