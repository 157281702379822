.admin-style {
  .navbar-dark {
    background-color: #2d2d2e;
    height: 60px;
    box-shadow: 0 0 20px 2px #131313;
    flex-wrap: nowrap;

    .dropdown-menu {
      right: 15px;
      top: 60px;
      left: unset;
      border-radius: 8px;
      background-color: #2d2d2e;
      box-shadow: 0 0 20px 2px #131313;

      &::before {
        content: '';
        position: absolute;
        left: auto;
        right: 18px;
        top: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #2d2d2e transparent;
        z-index: 9999;
      }

      li:not(:last-child) {
        border-bottom: 1px solid #b0b0b021;
      }

      .dropdown-item {
        color: #fff;
        height: 38px;
        display: flex;
        align-items: center;

        &:hover,
        &:active,
        &:focus,
        &:focus:active {
          cursor: pointer;
          background-color: #3c3d3e;
        }
      }
    }

    .dropdown .nav-link {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #36b4e5;
      right: 15px;
      position: relative;
      border: none;
      font-weight: 700;
      color: #fff;

      &::after {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item {
      font-size: 14px;

      a {
        color: #fff;
      }
    }
  }
}
