.ant-upload-picture-card-wrapper {
    width: 112px;
}

.upload-photo {
    button {
        width: 120px;
    }
}

.ant-upload-picture-card-wrapper,
.ant-upload-picture-card-wrapper,
.ant-upload-list-picture-card-container {
    width: 120px;
    height: 120px;
}

.ant-upload-list.ant-upload-list-picture-card {
    width: 100%;
    height: 120px;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 120px;
    margin: 0;
    background-color: #48494c;
    border: 1px dashed #b0b0b042;

    &:hover {
        border-color: #b0b0b042;
    }
    
    span {
        color: #fff;    
    }
}

.ant-upload-list-picture-card .ant-upload-list-item {
    border-color: #b0b0b042; 
}

.template {
    width: 700px;
    height: 260px;
    display: flex;
    align-items: flex-end;

    .background {
        position: relative;
        width: 700px;
        height: 180px;
        background-color: #212529;
    
        .faixaBolinhas {
            position: absolute;
            width: 25px;
            height: 172px;
            top: 4px;
            left: 3px;
        }
    
        .backgroundPicture {
            position: absolute;
            width: 160px;
            height: 160px;
            top: -80px;
            left: 50px;
            background-color: #36B4E5;
            border: 5px solid #36B4E5;
            border-radius: 100%;
            overflow: hidden;
    
            img {
                width: 150px;
                height: 150px;
                object-fit: contain;
                display: none;
            }
        }
    
        .nameAndJobRole {
            position: absolute;
            width: 250px;
            height: 45px;
            background-color: #36B4E5;
            right: 218px;
            top: -22.5px;
    
            .name {
                margin: 0;
                padding-left: 10px;
                font-family: Segoe UI;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                display: flex;
                align-items: center;
                color: #FFFFFF;
            }
            
            .jobRole {
                margin: 0;
                padding-left: 10px;
                font-family: Segoe UI;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 17px;
                display: flex;
                align-items: center;
                color: #212529;
            }
        }
    
        .email {
            position: absolute;
            margin: 0;
            font-family: Segoe UI;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            bottom: 22px;
            left: 50px;
        }
    
        .logo {
            position: absolute;
            top: 17px;
            right: 18px;
        }
    
        .companyDetails {
            position: absolute;
            bottom: 22px;
            right: 22px;
    
            .websiteLink,
            .phoneNumber {
                margin: 0;
                font-family: Segoe UI;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 17px;
                display: flex;
                align-items: center;
                color: #FFFFFF;
            }
        }
    }
}