$submenu-bg-color: #303240;
$submenu-bg-color-collapsed: #303240;

//@import '~react-pro-sidebar/dist/scss';

.pro-sidebar {
  position: fixed;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  bottom: 0;

  .pro-sidebar-inner {
    background-color: #2d2d2e;
    box-shadow: 0 0 20px 2px #131313;

    .pro-sidebar-layout {
      overflow: hidden;
    }
  }

  .pro-menu {
    padding-bottom: 0;

    .pro-menu-item {
      font-size: 14px;

      & > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
        animation: none;
      }
    }
    & > ul > .pro-sub-menu > .pro-inner-list-item {
      background-color: #373738;
    }
  }
}

// Responsividade
.pro-sidebar.collapsed {
  width: 70px !important;
  min-width: 70px !important;

  ~ .page-content {
    margin-left: 70px;
  }

  .popper-element[data-popper-placement^='right'] > .popper-arrow {
    display: none !important;
  }

  .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
    transform: translate(70px, 75px) !important;

    & > .popper-inner {
      background-color: #373738;
      box-shadow: 0 0 20px 2px #131313;
    }
  }
}
