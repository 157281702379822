.admin-style {
  .wysiwyg-container {
    $lowContrast: #48494c;
    $tableContrast: #3c3d3e;
    $tableContrast: #3c3d3e;

    $radius: 8px;

    .ql-toolbar {
      background-color: $lowContrast;
      border: 1px solid $lowContrast;
      border-bottom: 1px solid $tableContrast;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;

      .ql-formats {
        span {
          color: white;
          font-size: 15px;
          font-weight: 600;
        }
        button svg {
          .ql-stroke {
            stroke: white;
          }
        }
        .ql-stroke {
          stroke: white;
        }
        .ql-fill {
          fill: white;
        }

        .ql-expanded {
          .ql-picker-label {
            background-color: #323335;
            border: 1px solid #323335;
            border-top-left-radius: $radius;
            border-top-right-radius: $radius;
          }
          .ql-picker-options {
            background-color: #323335;
            border: 1px solid #323335;
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
          }
        }
      }
    }

    .wysiwyg-editor {
      background-color: $lowContrast;
      border: 1px solid $lowContrast;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
      height: 300px;
      color: white;

      ::before {
        color: white;
      }
    }

    .wysiwyg-editor.wysiwyg-light {
      color: #191a1d;
      background-color: white;
    }

    .ql-change-background {
      color: white;

      &:hover,
      &:focus {
        color: white !important;
      }
    }

    .ql-tooltip {
      background-color: #323335;
      border: 1px solid #323335;
      border-radius: $radius;
      box-shadow: none;
    }

    #emoji-palette {
      #tab-toolbar {
        background-color: #323335;
        border-color: #323335;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
      }
      #tab-panel {
        background-color: #323335;
        border-color: #323335;
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;

        span {
          margin: 4px;
        }
      }
    }
  }
}
