.h-60px {
  height: 60px !important;
}
.card-container {
  .card {
    border: none;
    box-shadow: 0px 0px 15px 1px #71717138;
  }
  .card {
    box-shadow: 0px 0px 15px 1px #717171eb !important;
  }
  .card-header {
    background-color: #eaeaea;
  }
  .card .menu-prev:hover,
  .card .menu-next:hover {
    cursor: pointer;
  }
  .nav-link {
    color: #717171;
  
    &:hover {
      color: #414141;
    }
  }
}
.mw-175px {
  max-width: 175px;
}
.mw-50px {
  max-width: 50px;
}
.nbw-blue {
  color: #36b4e5;
}
.container-title {
  margin-top: 160px;
}
.container-technologies {
  margin-top: 150px;
}
.carousel-technologies {
  margin-top: 50px;
}

// Responsividade
@media (max-width: 1000px) {
  .container-technologies {
    margin-top: 120px;
  }
  .carousel-technologies {
    margin-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .container-title {
    margin-top: 100px;
  }
  .container-technologies {
    margin-top: 80px;
  }
  .carousel-technologies {
    margin-bottom: 50px;

    img {
      height: 60px;
    }
  }
  .tech-logos img {
    height: 50px;
  }
}
@media (max-width: 480px) {
  .card-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
