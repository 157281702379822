.vacancy-card {
  border: 1px solid rgb(189, 190, 190);
  margin-top: 30px;
  margin-bottom: 30px;

  .custom-label {
    left: 10px;
    bottom: 8px;
    position: absolute;
    transition: 0.5s;
    width: 100%;
    margin-bottom: 0;
  }

  a {
    left: -10px;
    position: absolute;
    transition: 1s;
    width: 230px;
    display: flex;
    flex-direction: column;

    span {
      color: #212529;
      font-size: 17px;
    }

    p {
      color: #212529;
      font-size: 16px;
      font-weight: 500;
    }
  }

  svg {
    right: 15px;
    position: absolute;
    bottom: -10px;
    transition: 1s;
  }
}

.vacancy-card {
  width: 300px;
  height: 250px;
  border: 1px solid #212529;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 0;
  transition: 0.5s;
}

.vacancy-card::before,
.vacancy-card::after {
  position: absolute;
  background: white;
  z-index: -1;
  transition: 0.5s;
  content: "";
}

.vacancy-card::before {
  height: 110px;
  width: 60px;
  top: -5px;
  left: -5px;
}

.vacancy-card::after {
  height: 0px;
  width: 0px;
  bottom: -5px;
  right: -5px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vacancy-card:hover::before {
  width: 0px;
  height: 0px;
  background: #fff;
  transition: 1s;
}

.vacancy-card:hover::after {
  width: 60px;
  height: 60px;
  background: #fff;
  transition: 1s;
}

.vacancy-card:hover {
  background: #fff;

  &.vacancy-card {
    a {
      left: 15px;
      transition: 0.5s;
      color: #212529;
    }

    .custom-label {
      width: 100%;
      transition: 0.1s;
    }

    svg {
      right: 0px;
      color: #36b4e5;
      transition: 1s;
    }
  }
}
