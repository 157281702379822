.antd-table-center-div {
  display: flex;
  justify-content: center;

    .form-check-input {
      width: 20px;
      height: 20px;
      border-radius: 6px;

      &:not(:checked) {
        background-color: #3d3d3d !important;
        border-color: #3d3d3d !important;
      }
    }
}

.permissions-page .ant-table-wrapper .ant-table-tbody tr:hover .antd-table-center-div .form-check-input:not(:checked) {
  background-color: #48494c !important;
  border-color: #48494c !important;
}