.collaborators-register {
  label {
    color: #fff;
  }

  .dynamic-card .content-card .card-body {
    .address-card,
    .contact-card,
    .dependent-card {
    font-size: 14px;
    line-height: 20px;

    p {
      margin: 0;
    }
  }
  }
}
