.dynamic-card {
  background-color: #323335 !important;
  border-color: #323335 !important;
  box-shadow: 0px 0px 50px 0px #15151573 !important;
  min-height: 74px;

  .card-header {
    display: flex;
    justify-content: center;

    h2 {
      height: 24px;
      font-size: 22px;
      line-height: 22px;
      font-weight: 600;
      color: #fff;
      margin: 5px;
    }
  }

  .card-body .content-card {
    background-color: #48494c !important;
    border-color: #48494c !important;
    box-shadow: 0px 0px 50px 0px #15151573 !important;

    &:hover,
    &:active,
    &:focus,
    &:focus:active,
    &:focus-visible {
      border-color: #3c3c3c !important;
      background-color: #3c3c3c !important;
    }

    .card-body {
      color: #fff;
      padding: 12px;

      .remove-card-button {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid transparent;
        background-color: transparent;
        padding: 0;
        display: flex;

      &:hover,
      &:active,
      &:focus,
      &:focus:active,
      &:focus-visible {
        outline: none;
      }

        svg {
          color: #fff;
          width: 18px;
          height: 18px;
        }
      }
    }

    &:not(:first-child):not(:last-child) {
      margin: 16px 0 16px 0;
    }
    &:nth-child(2) {
      margin: 16px 0 16px 0;
    }
  }

  .card-body .content-card__selected {
    border-color: #3c3c3c !important;
    background-color: #3c3c3c !important;
  }

  .dynamic-card__buttons {
    display: flex;
    justify-content: space-between;

    button {
      width: 48%;
    }
  }
}

.dynamic-card__content {
  overflow: hidden;

  .scrollbar-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
