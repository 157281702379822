.react-date-picker {
  width: 100% !important;
  z-index: 2;

  .react-date-picker__calendar .react-calendar {
    background-color: #48494c;
    border: 1px solid #48494c;
    border-radius: 8px;
    box-shadow: 10px 0px 50px 0px #00000073;
    overflow: hidden;
    z-index: 99999999;
    position: absolute;

    .react-calendar__navigation button {
      color: white;

      &:hover,
      &:active,
      &:focus,
      &:focus:active {
        background-color: #48494c;
      }
      &[disabled] {
        background-color: #5d5d5d;
        opacity: 0.6;
      }
    }

    .react-calendar__month-view__days__day:disabled,
    .react-calendar__year-view__months__month:disabled,
    .react-calendar__century-view__decades__decade:disabled {
      background-color: #5d5d5d;
      opacity: 0.6;
    }

    .react-calendar__month-view__weekdays,
    .react-calendar__month-view__days__day,
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      color: white;
    }

    .react-calendar__month-view__days > .react-calendar__month-view__days__day--neighboringMonth {
      color: #aeafb7;
    }

    .react-calendar__month-view__days__day--weekend {
      color: #cf4141;
    }

    .react-calendar__tile--now {
      background-color: #3c3d3e;
    }

    .react-calendar__tile--active {
      background-color: #323335;
    }

    .react-calendar__tile {
      &:hover,
      &:active,
      &:focus,
      &:focus:active {
        background-color: #323335;
      }
    }
  }

  .react-date-picker__wrapper {
    flex-shrink: unset;
    border: 0px;
    background-color: #48494c;
    border: 1px solid #48494c;
    border-radius: 8px;
    height: 42px;
    overflow: hidden;

    .react-date-picker__inputGroup {
      display: flex;
      align-items: center;
      padding: 0px;

      .react-date-picker__inputGroup__input {
        padding: 0 0 0 0.5rem;
        border: none !important;
      }

      .react-date-picker__inputGroup__divider {
        color: #aeafb7;
        padding: 0 0.25rem 0 0.25rem;
      }
    }

    .react-date-picker__clear-button svg,
    .react-date-picker__calendar-button svg {
      stroke: #fff !important;
    }
  }
}

.react-date-picker--open .react-date-picker__inputGroup input {
  color: white;
}
