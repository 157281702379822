.appointment-row {
  label {
    color: #fff;
  }
}

.value-eye:not(.is-normal) {
  .icon {
    font-size: 24px;
    cursor: pointer;

    .slashed-eye {
      display: block;
    }
    .normal-eye {
      display: none;
    }
  }

  .text {
    color: transparent;
    text-shadow: 0 0 8px #fff;
  }
}

.value-eye.is-normal {
  .icon {
    font-size: 24px;
    cursor: pointer;

    .slashed-eye {
      display: none;
    }
    .normal-eye {
      display: block;
    }
  }
}

.form-radio {
  input[type='radio'] {
    height: 25px;
    width: 25px;
    flex-shrink: 0;
    cursor: pointer;
  }
}

.row.button-container {
  button {
    margin-left: 12px;
    margin-right: 12px;
  }
}
