.about {
  margin-top: 160px;

  p {
    font-size: 16px;
  }

  img {
    margin-left: -100px;
    height: 472.98px;
    border-radius: 0.25rem;
  }
}

#bannerMission {
  opacity: 0;
}

.banner-mission {
  margin: 200px auto 0px auto;
  background: linear-gradient(163deg, #18355e 10%, #36b4e5 100%);
  max-width: 100%;
  padding: 50px 12px;

  .slide-right {
    animation: none;
  }

  strong {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
  }

  label {
    font-size: 16px;
    color: #fff;
  }
}

.carousel-container {
  margin-top: 200px;
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .banner-mission {
    .slide-right {
      margin-top: 50px;
    }
  }
}
@media (min-width: 992px) {
  .banner-mission {
    .slide-right {
      margin-left: -100px;
      margin-right: 100px;
    }
  }
  .slide-right {
    -webkit-animation: slide-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
    animation: slide-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
  }
  @-webkit-keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }
  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }
  .slide-left {
    -webkit-animation: slide-left 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}
@media (max-width: 575px) {
  .banner-mission {
    div:nth-child(2) {
      margin-top: 50px;
    }
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .about {
    img {
      height: 360px;
      border-radius: 0.25rem;
    }
  }
}
@media (max-width: 767px) {
  .about {
    margin-top: 100px;

    .title-2,
    .subtitle {
      text-align: center;
    }
    .text-highlight {
      display: flex;
      justify-content: center;
    }

    img {
      margin-top: 50px;
      margin: 50px auto 0 auto;
      display: flex;
    }
  }
}
@media (max-width: 1000px) {
  .banner-mission {
    margin-top: 100px;
  }
  .carousel-container {
    margin-top: 100px;
  }
}
@media (max-width: 500px) {
  .banner-mission {
    margin-top: 80px;
  }
  .carousel-container {
    margin-top: 80px;
  }
}
@media (max-width: 420px) {
  .about {
    img {
      height: 360px;
      margin: 50px auto 0 auto;
    }
  }
}
@media (max-width: 375px) {
  .about {
    img {
      height: 300px;
      margin: 50px auto 0 auto;
    }
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInAbout {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInAbout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAbout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.modal{
  text-align: justify-all;
}
