footer {
    background: #212529;
    border-top: 4px solid #212529;
    font-size: 0.9em;
    padding: 0;
    position: relative;
    clear: both;
    margin-top: 100px;
    box-shadow: 0 0 20px 2px rgba(209, 21, 21, 0.42);
  
    .pages-links {
      justify-content: center;
      flex-wrap: wrap;
  
      li {
        display: flex;
        align-items: center;
      }
    }
  
    h5 {
      color: #fff;
      font-size: 19px;
      font-weight: 600;
    }
  
    p {
      color: #777;
      line-height: 26px;
      margin: 0 0 20px;
      font-size: 15px;
    }
  
    a {
      font-size: 15px;
      color: #777 !important;
      text-decoration: none;
  
      &:hover {
        color: #fff !important;
      }
    }
  
    .footer-copyright {
      background: #212529;
      border-top: 1px solid rgba(255, 255, 255, 0.05);
      color: #777;
      font-size: 13px;
    }
  
    .footer-logo {
      height: 100px;
    }

    .footer-logo-senior{
      height: 50px;
      position: relative;
      top: 30px;
    }
  
    .footer-social-icons {
      display: flex;
      padding-left: 0;
  
      .social-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50px;
        cursor: pointer;
  
        &:nth-child(1) {
          background-color: #3b5998 !important;
  
          &:hover {
            background-color: #4c6fbb !important;
          }
        }
  
        &:nth-child(2) {
          background-color: #0e76a8 !important;
  
          &:hover {
            background-color: #209ed8 !important;
          }
        }
  
        &:nth-child(3) {
          background-color: #25d366 !important;
  
          &:hover {
            background-color: #6ad391 !important;
          }
        }
  
        &:nth-child(4) {
          background-color: #25d366 !important;
  
          &:hover {
            background-color: #6ad391 !important;
          }
        }
      }
    }
  
    .footer-powered-by {
      margin: 0;
      line-height: 0;
      right: 0;
      position: absolute;
      display: flex;
  
      .footer-powered-by span {
        margin: -6px -54px;
        font-size: 10px;
      }
  
      img {
        display: inline-block;
        width: 80px;
        height: 40px;
        font-size: 0;
        color: #fff;
      }
    }
  
    @media (max-width: 991px) {
      .footer-logo {
        height: 100px;
      }
    }
  
    @media (min-width: 769px) {
      .footer-powered-by {
        img {
          display: inline-block;
          width: 80px;
          height: 40px;
          font-size: 0;
          color: #fff;
        }
  
        span {
          margin: -6px -54px;
          font-size: 10px;
        }
      }
    }
  
    @media (min-width: 1001px) {
      .footer-powered-by {
        img {
          display: inline-block;
          width: 80px;
          height: 40px;
          font-size: 0;
          color: #fff;
        }
  
        span {
          margin: -6px -54px;
          font-size: 10px;
        }
      }
    }
  
    @media (max-width: 500px) {
      margin-top: 0px;
    }
  }
  
  