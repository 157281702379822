.contact {
  margin-top: 160px;
}
.address {
  a {
    color: #36b4e5;
    text-decoration: underline;

    &:hover {
      color: #2c9bc7;
    }
  }
}

// Responsividade
@media (max-width: 767px) {
  .contact {
    margin-top: 100px;
  }
  .address {
    margin-top: 80px;
  }
}
@media (max-width: 500px) {
  .contact {
    margin-top: 100px;
  }
  .address {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
