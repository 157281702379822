.carreer-form-container {
  margin-top: 160px;

  .form-check {
    padding-left: 36px;
  }
}

// Responsividade
@media (max-width: 767px) {
  .carreer-form-container {
    margin-top: 100px;
  }
}
