.carousel-testimony {
  margin-top: 160px;
  margin-bottom: 80px;

  button {
    top: 80px;
  }

  .text-center {
    padding-right: 150px;
    padding-left: 150px;

    img {
      margin-top: 40px;
      height: 140px;
      width: 140px;
      border-radius: 50%;
      box-shadow: 0 0 10px 2px #00000080;
    }
  }

  .subtitle-2 {
    line-height: 5px;
  }
}

.contact-rh {
  margin-top: 160px;
  margin-bottom: 50px;
}

@media (max-width: 800px) {
  .carousel-testimony {
    margin-top: 100px;
    margin-bottom: 80px;

    .text-center {
      padding-right: 20px;
      padding-left: 20px;

      img {
        margin-top: 40px;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        box-shadow: 0 0 20px 2px #0000006b;
      }
    }

    .subtitle-2 {
      line-height: 5px;
    }
  }
}
@media (min-width: 720px) and (max-width: 800px) {
  .carousel-testimony {
    padding-right: 0;
    padding-left: 0;
  }
}

.vacancy-container {
  a {
    font-size: 18px;
    font-weight: 700;
    color: #36b4e5;
    outline: none;
    text-decoration: none;
    background-color: #fff;
  }

  .title-2 {
    margin-top: 80px;
    margin-bottom: 48px;
  }
}

// Responsividade
@media (max-width: 767px) {
  .carousel-testimony {
    margin-bottom: 0px;
  }
  .vacancy-container {
    .title-2 {
      margin-top: 80px;
      margin-bottom: 0px;
    }
  }
  .contact-rh {
    margin-top: 80px;
  }
}
