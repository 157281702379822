// TimePicker Antd
.ant-picker-footer {
  display: none;
}

/* Cores */
$primary: #36b4e5;
$primaryEvent: #2e96bf;
$secondary: #18355e;
$high: #191a1d;
$medium: #25262f;
$low: #323335;
$highContrast: #ffffff;
$lowContrast: #48494c;

$textLow: #dfdfdf;

$shadow: #000000;
$outfocusText: #aeafb7;
$tableContrast: #3c3d3e;
$sidebarContrast: #303240;

$default: #ffffff;
$defaultEvent: #ececec;
$success: #29b870;
$successEvent: #30d984;
$warning: #f8ad19;
$warningEvent: #f5d147;
$danger: #cf4141;
$dangerEvent: #ff4f4f;

$radius: 8px;
$fieldHeight: 32px;
$fontName: 'Segoe UI';
$fontDefault: 13px;
$fontLabel: 14px;
$fontTitle: 16px;

.admin-style {
  background-color: #191a1d;
  min-height: calc(100vh);

  .page-content {
    margin-top: 60px;
    margin-left: 270px;
    transition: margin-left 0.3s ease;
    padding: 16px;
  }

  // Border colors
  .border-danger {
    border-color: $danger !important;
  }
  .border-success {
    border-color: $success !important;
  }

  // Alert
  .alert {
    padding: 12px;
  }

  // Card
  .card {
    background-color: #2d2d2e;
    border: 1px solid #2d2d2e;
    border-radius: $radius;
    box-shadow: 0px 0px 50px 0px #00000073;

    .card-header {
      border-color: #b0b0b021;

      .ant-breadcrumb {
        font-size: $fontTitle;
        font-weight: bold;

        .ant-breadcrumb-separator,
        .ant-breadcrumb-link a {
          color: $textLow;
          margin: 2px !important;
          

          svg {
            cursor: default;
            position: inherit;
          }
        }

        .ant-breadcrumb-link a {
          text-decoration: underline;
        }

        .ant-breadcrumb-link {
          color: $highContrast;
        }
      }

      svg {
        cursor: pointer;
        position: absolute;
        left: 13px;
      }
    }

    .card-body {
      label {
        color: $textLow;
        font-size: $fontLabel;
      }
    }
  }

  // Input & Textarea
  input,
  textarea {
    height: $fieldHeight;
    border-radius: $radius;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-weight: 600;
    font-size: $fontDefault;
    line-height: 1.5;
    background-clip: padding-box;
    background-color: $lowContrast !important;
    border: 1px solid $lowContrast !important;
    color: $outfocusText;
    box-shadow: none !important;
    transition: none;
    scrollbar-width: none; /*Firefox*/

    &:focus {
      outline: none !important;
      color: $highContrast !important;
    }

    &:-webkit-autofill {
      border-width: 8px;
      padding: 5px;
      -webkit-box-shadow: 0 0 0 1000px $lowContrast inset !important;
      box-shadow: 0 0 0 1000px $lowContrast inset !important;
      -webkit-text-fill-color: $outfocusText !important;
      caret-color: $highContrast;

      &:focus {
        -webkit-text-fill-color: $highContrast !important;
      }
    }

    &:-webkit-scrollbar {
      /*WebKit Browsers*/
      width: 0;
      height: 0;
    }
  }
  textarea {
    height: auto;
  }
  .wysiwyg-container .ql-toolbar .ql-formats span{
    :not(:nth-child(0)){
      margin-top: -2px !important;
    }
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg{
   margin-top: -8px !important;
  }

  // Select & Multiselect
  .multiselect-container,
  .select-container {
    height: $fieldHeight;
    color: $highContrast;

    .multiselect__control,
    .select__control {
      height: $fieldHeight;
      background-color: $lowContrast;
      border: 0px;
      border-radius: $radius;
      overflow: hidden;

      .multiselect__value-container,
      .select__value-container {
        height: $fieldHeight;
        padding: 0px 8px;

        .select__single-value {
          color: $outfocusText;
        }

        .multiselect__multi-value {
          background-color: transparent;

          div {
            color: $outfocusText;
          }
        }

        .multiselect__input input,
        .select__input input,
        .multiselect__values-select {
          margin-top: -6px;
          color: $outfocusText;
        }
      }

      .multiselect__indicators,
      .select__indicators {
        .multiselect__indicator-separator,
        .select__indicator-separator {
          background-color: transparent;
        }
      }

      .multiselect__option,
      .select__option {
        border-radius: $radius;
      }
    }

    .select__control--is-focused {
      .select__value-container {
        .select__single-value,
        .select__placeholder {
          color: $highContrast;
        }
      }
    }

    .multiselect__control--is-focused {
      .multiselect__value-container {
        .multiselect__placeholder {
          color: $highContrast;
        }

        .multiselect__values-select {
          color: $highContrast;
        }
      }
    }
  }

  // Grid Select
  .gridselect-container {
    height: 45px;

    .gridselect__menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: 0;
      box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.25);

      .scrollbar-container {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .gridselect__option {
          padding: 8px;

          .form-check-input {
            margin-right: 8px !important;
          }
        }
      }
    }

    .gridselect__control {
      height: 45px;
      background-color: #414244;
      border: 0px;
      border-radius: 0;
      transition: none;

      .gridselect_menu {
        background-color: #414244;
      }

      .gridselect__value-container {
        height: 45px;
        padding: 0px 8px;

        .gridselect__single-value {
          color: $outfocusText;
        }

        .gridselect__input input {
          margin-top: -6px;
          color: $outfocusText;
          background-color: transparent !important;
          border-color: transparent !important;
        }
      }

      .gridselect__indicators {
        .gridselect__dropdown-indicator {
          padding: 4px 4px 4px 0;

          svg {
            height: 22px;
            width: 22px;
          }
        }
        .gridselect__clear-indicator {
          padding: 4px 0 4px 4px;

          svg {
            height: 22px;
            width: 22px;
          }
        }
        .gridselect__indicator-separator {
          background-color: transparent;
        }
      }

      .gridselect__option {
        border-radius: $radius;
      }
    }

    .gridselect__control--is-focused {
      .gridselect__value-container {
        .gridselect__single-value,
        .gridselect__placeholder {
          color: $highContrast;
        }
      }
    }
  }

  // Estilo Perfect Scrollbar
  .scrollbar-container {
    border-radius: 8px;

    .ps__rail-y,
    .ps__rail-x {
      background-color: $tableContrast;
      width: 10px;
      height: 10px;
      opacity: 1 !important;
      z-index: 2;

      &:hover,
      &:active,
      &:focus,
      &:focus:active {
        background-color: $tableContrast;
      }
      .ps__thumb-y,
      .ps__thumb-x {
        background-color: #313131;
        width: 10px;
        height: 10px;
        border-radius: $radius;
        bottom: 0;
        left: 0;

        &:hover,
        &:active,
        &:focus,
        &:focus:active {
          cursor: pointer;
        }
      }
    }

    .ps__rail-y.ps--clicking,
    .ps__rail-x.ps--clicking {
      background-color: $tableContrast;
    }

    .ps__rail-x {
      width: 100% !important;
    }
  }
  .sidemenu-scrollbar {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* Input File */
  .custom-input-file {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    z-index: 0;

    .input-file {
      position: relative;
      z-index: 2;
      margin: 0;
      opacity: 0;
      height: $fieldHeight !important;

      &:focus ~ .custom-file-label {
        box-shadow: none;
      }

      &:lang(en) ~ .custom-file-label::after {
        content: 'Browse';
      }
    }

    .input-file-label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      height: $fieldHeight;
      padding: 8px 12px 6px 12px;
      line-height: 1.5;
      background-color: $lowContrast;
      border-radius: $radius;
      border: 1px solid $lowContrast;
      color: $highContrast;
      font-weight: 600;
      margin: 32px 12px 0px 12px;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 3;
        height: $fieldHeight;
        display: block;
        margin-top: -1px;
        padding: 8px 30px;
        padding-top: 7px;
        line-height: 1.5;
        content: 'Buscar';
        border: 1px solid $primary;
        border-radius: 8px 0 0 8px;
        background-color: $primary;
      }
    }

    .new-after::after {
      margin-top: 0px;
      height: 40px;
    }
  }

  // Input Password

  .pswd-eye {
    input {
      border-right: none !important;
    }

    span {
      // font-size: 24px;
      background-color: $lowContrast;
      color: $outfocusText;
      cursor: pointer;
      border: 1px solid $lowContrast;
      border-radius: $radius;
      border-left: none;

      .slashed-eye {
        display: block;
      }
      .normal-eye {
        display: none;
      }
    }

    .is-normal {
      .slashed-eye {
        display: none;
      }
      .normal-eye {
        display: block;
      }
    }
  }

  // Input checkbox
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    margin-top: 0;

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      cursor: pointer;
    }

    &:checked {
      background-color: $primary !important;
      border-color: $primary !important;

      &:hover,
      &:active,
      &:focus,
      &:focus:active {
        background-color: $primaryEvent !important;
        border-color: $primaryEvent !important;
      }
    }
  }
  .form-check {
    display: flex;
    align-items: center;
  }
  .form-check-label {
    color: $highContrast;
  }
  .check-contrast,
  .grid-check-contrast {
    label {
      cursor: pointer;
    }

    input[type='checkbox'] {
      background-color: #3d3d3d !important;
      border-color: #3d3d3d !important;
      width: 20px;
      height: 20px;
      padding: 8px;
      border-radius: 6px;
      margin-top: 3.5px;

      &:checked {
        background-color: $primary !important;
        border-color: $primary !important;

        &:hover,
        &:active,
        &:focus,
        &:focus:active {
          background-color: $primaryEvent !important;
          border-color: $primaryEvent !important;
        }
      }
    }
  }

  /* Buttons */
  .btn,
  .ant-btn {
    border-radius: $radius;
    border: 1px solid;
    height: $fieldHeight;
    padding: 5px 20px;
    font-weight: 600;
    line-height: 26px;
    padding: 5px 23px;
    letter-spacing: 0.4px;
    text-align: center;
    user-select: none;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    color: $highContrast;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $fontLabel;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
      border: 1px solid;
    }

    &.disabled,
    &:disabled {
      opacity: 0.3;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: none;
    }

    &:focus,
    &:focus:active,
    &:active,
    &:hover,
    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      color: $highContrast;
    }
  }
  .btn-default,
  .ant-btn-default {
    background-color: $default;
    border-color: $default;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $defaultEvent;
      border-color: $defaultEvent;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $default;
      border-color: $default;
    }
  }
  .btn-default-outline {
    background-color: transparent;
    border-color: $default;
    color: $default;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $default;
      border-color: $default;
      color: $high;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $default;
      border-color: $default;
    }
  }
  .btn-primary,
  .ant-btn-primary {
    background-color: $primary;
    border-color: $primary;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $primaryEvent;
      border-color: $primaryEvent;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .btn-primary-outline {
    background-color: transparent;
    border-color: $primary;
    color: $primary;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $primary;
      border-color: $primary;
      color: $highContrast;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .btn-success {
    background-color: $success;
    border-color: $success;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $successEvent;
      border-color: $successEvent;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $success;
      border-color: $success;
    }
  }
  .btn-success-outline {
    background-color: transparent;
    border-color: $success;
    color: $success;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $success;
      border-color: $success;
      color: $highContrast;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $success;
      border-color: $success;
    }
  }
  .btn-danger {
    background-color: $danger;
    border-color: $danger;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $dangerEvent;
      border-color: $dangerEvent;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $danger;
      border-color: $danger;
    }
  }
  .btn-danger-outline {
    background-color: transparent;
    border-color: $danger;
    color: $danger;

    &:focus,
    &:focus:active,
    &:active,
    &:hover {
      background-color: $danger;
      border-color: $danger;
      color: $highContrast;
    }

    &.disabled,
    &:disabled,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      background-color: $danger;
      border-color: $danger;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  // Tabela
  .react-bootstrap-table {
    max-height: 500px;

    .table-bordered > :not(caption) > * > * {
      border-width: 0;
    }

    .table {
      border-radius: $radius;
      display: inline-table;
      margin: 0;
      border-collapse: separate;
      border-spacing: 0;

      tbody {
        &:first-of-type tr th {
          padding: 0;
          overflow: unset;

          .gridselect-container {
            background-color: $lowContrast;
          }

          &:hover {
            .gridselect__clear-indicator {
              color: $highContrast;
            }
          }
        }
      }
    }

    .default-table {
      th {
        border-bottom: 1px solid $tableContrast;
      }
      td {
        border-bottom: 1px solid $tableContrast;
      }
      td:not(:nth-last-child(2)) {
        border-right: 1px solid $tableContrast;
      }
    }
    .permissions-table {
      th,
      td {
        border-bottom: 1px solid $tableContrast;
        border-right: 1px solid $tableContrast;
      }
      th:not(:first-child) {
        text-align: center;
      }
    }

    .column-action,
    .show-actions tbody:first-of-type tr th:last-child {
      background-color: #515255 !important;
      border-color: $tableContrast !important;
      box-shadow: -4px 8px 8px 0 rgba(12, 12, 12, 0.25);
      position: sticky;
      position: -webkit-sticky;
      right: 0;
      z-index: 1;
      min-width: 70px !important;
      text-align: center;
      border-left: 1px solid $tableContrast !important;
    }
    .show-actions tbody:first-of-type tr th:last-child,
    thead tr th:last-child {
      box-shadow: none;
    }

    td,
    th {
      height: 45px;
      min-width: 180px;
      max-width: 350px;
      overflow: hidden;
      border-color: $tableContrast;

      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }

      a {
        color: $primary;
      }
    }

    tr {
      border: 1px solid $tableContrast;
      background: $lowContrast;
      border-width: 1px 0 0 0;

      &:hover {
        border-color: $lowContrast;
        background-color: $tableContrast;

        th {
          border-bottom: 1px solid $lowContrast;
        }
        td {
          border-bottom: 1px solid $lowContrast;
        }
        td:not(:nth-last-child(2)) {
          border-right: 1px solid $lowContrast;
        }

        td {
          border-color: $lowContrast;

          .grid-check-contrast input[type='checkbox'] {
            background-color: $lowContrast !important;
            border-color: $lowContrast !important;

            &:checked {
              background-color: $primary !important;
              border-color: $primary !important;

              &:hover {
                background-color: $primaryEvent !important;
                border-color: $primaryEvent !important;
              }
            }
          }
        }

        .react-bootstrap-table-editing-cell {
          .input-grid {
            background-color: #323335 !important;
            border-color: $tableContrast !important;
          }
        }

        .gridselect-container .gridselect__control {
          background-color: $tableContrast !important;
        }
      }
    }

    thead tr {
      position: sticky;
      top: 0;
      z-index: 2;
    }
    tbody:first-of-type tr th {
      box-shadow: 0px 2px 8px 0 rgba(12, 12, 12, 0.25);
      position: sticky;
      top: 45px;
      z-index: 1;
    }
    tbody:first-of-type tr th:last-child {
      z-index: 2 !important;
    }

    thead tr,
    tbody:first-of-type tr {
      background-color: #515255;

      th:not(:nth-last-child(2)) {
        border-right: 1px solid $tableContrast !important;
      }

      .gridselect__control {
        background-color: #515255;
      }

      &:hover {
        cursor: pointer;
        border-color: $tableContrast;
        background-color: #515255;

        .gridselect__control {
          background-color: #515255 !important;
        }

        .gridselect__control--menu-is-open {
          background-color: $lowContrast !important;
        }
      }

      .gridselect__control--menu-is-open {
        background-color: $lowContrast !important;
        border-bottom: 1px solid $tableContrast;
      }
    }

    tbody:last-of-type tr:last-child {
      border-bottom: 1px solid $tableContrast;
    }

    tbody tr,
    thead tr {
      color: $highContrast;
    }

    .react-bootstrap-table-editing-cell {
      padding: 0;
      overflow: unset;
      border: none !important;

      .input-grid {
        background-color: $tableContrast !important;
        border-color: $tableContrast !important;
        border-top: none !important;
        border-left: none !important;
        border-radius: 0;
        height: 45px;
        padding: 8px;
      }
    }
  }
  .table-scrollbar {
    box-shadow: 0px 0px 12px 4px rgba(26, 26, 26, 0.42);
    background-color: $lowContrast;
  }
  .grid-pagination {
    width: 75px;
  }

  .rmsc {
    .dropdown-container {
      background-color: transparent;
      border: none;
    }
  }

  /* Estilo modal */
  .modal-custom {
    padding-left: 17px;

    .modal-icon {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      top: -35px;
      background-color: $lowContrast;
      margin: auto;
      margin-bottom: -35px;
      position: inherit;
      color: $warning;

      .exclamation-triangle {
        position: absolute;
        top: 10px;
        left: 15px;
      }
    }

    .modal-header {
      border-bottom: none;
      justify-content: space-evenly;
      text-align: center;
      color: $highContrast;

      .modal-title {
        color: $textLow;
      }
    }

    .modal-content {
      border-radius: $radius;
      background-color: #2d2d2e;
      border: none;
      -webkit-box-shadow: 0 1px 20px -8px $shadow;
      box-shadow: 0 1px 20px -8px $shadow;
    }

    .modal-dialog {
      margin-top: 40px;
    }

    .modal-footer {
      border-top: 2px solid #b0b0b021 !important;
      justify-content: space-evenly;
    }

    button {
      width: 40%;
    }

    .modal-body .checkbox-div label {
      cursor: pointer;
    }
  }

  /* Estilo botão AntD */
  .ant-btn {
    background-color: $lowContrast !important;
    border-color: $outfocusText !important;
    svg {
      color: $highContrast;
    }
  }
  .ant-btn:hover {
    background-color: $lowContrast;
    border-color: $high;
    svg {
      color: $highContrast;
    }
  }
  .ant-input-affix-wrapper{
    background-color:$lowContrast;
    border: $lowContrast;
    border-radius: 8px;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    &:hover{
      border-color: $lowContrast;
    }
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    box-shadow: 0 0 0 0;
  }
  .ant-input-password-icon{
    color: $outfocusText;

    svg path:nth-child(3), svg path:nth-child(4){
      fill: $primary;
    }
  }

  .ant-input-password-icon:hover {
    color: $highContrast;
}

  /* Estilo da Tabela AntD */
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > td {
    border-right: 1px solid $medium !important;
  }
  .ant-dropdown-link {
    color: #ebebeb;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -8px;
  }
  .ant-table-sticky-holder {
    z-index: 2 !important;
  }
  .ant-table-column-title {
    font-size: $fontLabel;
  }
  .ant-table-column-sorter,
  .ant-table-filter-trigger {
    color: #e6e7e4 !important; /*itens filtro de busca*/

    .ant-table-column-sorter-inner span.active {
      color: $primary;
    }
  }
  .ant-table-column-title {
    line-height: 1;
  }
  .ant-table-body {
    // overflow-y: overlay !important;
    max-height: 365px;

    .ant-empty {
      color: $highContrast;
    }
  }
  tbody.ant-table-tbody {
    background: #252526;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th:last-child {
    border-right: none !important;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #898989;
    border-radius: 20px;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background: transparent;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .ant-table-sticky-holder {
    overflow: hidden !important;
  }
  .ant-table-wrapper {
    border-radius: $radius;
    box-shadow: 0px 0px 0px 0 $medium; /*-4px 8px 8px 0*/
  }
  .ant-row.ant-form-item input {
    box-shadow: 2px 2px 5px 2px $shadow !important; /*2px 2px 5px 2px*/
  }
  .ant-table-cell {
    background: $lowContrast !important;
    padding: 6px 13px !important;
    font-size: $fontDefault;

    span[role='presentation'] {
      color: $primary !important;
    }

    &::after {
      border-right: none !important;
    }

    &.ant-table-selection-column .ant-radio {
      .ant-radio-inner {
        background-color: $low !important;
        border-color: $low !important;
      }

      &.ant-radio-checked .ant-radio-inner::after {
        background-color: $primary !important;
        border-color: $primary !important;
      }
    }
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
    border-color: #252526 !important; /*gradezinhas*/
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td:last-child {
    border-color: #25262f !important; /*gradezinha direita*/
    border-left: none !important;
    border-right: none !important;
  }
  .ant-table-tbody > tr > td:last-child {
    padding: 0;
    border-bottom: 1px solid $highContrast !important;
    border-left: 1px solid $highContrast !important;
    box-shadow: 0px 0px 0px 0 rgb(199, 199, 199); /*sombra da direita*/
  }
  .ant-pagination-item {
    border-radius: 13px !important;
    border-color: $lowContrast !important;
    color: $textLow !important;
    border-radius: 50% !important;
    background: $lowContrast !important;
  }
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: $highContrast !important; /*cor do numero de pagina*/
  }
  .ant-pagination-item:hover {
    font-weight: 500 !important;
    background: $tableContrast !important;
    border-color: $highContrast !important;
  }
  .ant-pagination-item-active {
    font-weight: 500 !important;
    background: $lowContrast !important;
    border-color: $highContrast !important;
  }
  #inputSearch {
    border-radius: 13px !important;
  }
  .ant-row.ant-form-item input {
    background: black;
  }
  .ant-table-row {
    &:hover > td {
      background-color: $tableContrast !important;
    }
    &.ant-table-row-selected > td {
      background-color: $lowContrast !important;
    }
  }
  .allRegisters-table {
    color: $textLow; /*total de registros*/
    margin-right: 10px;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent !important;
    border: none !important;
    font-size: 15px !important;
    display: flex !important;
    align-items: center !important;
    padding: revert !important;
    svg:last-child {
      color: $outfocusText;

      &:hover {
        color: $highContrast;
      }
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #adafb2;
    color: $textLow !important; /*cor da letra*/
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: #515255 !important; /*coluna direita 'ações'*/
  }
  .ant-table-sticky-holder,
  .ant-table {
    background: none !important;
  }
  td.ant-table-cell p {
    margin-bottom: 0 !important;
  }
  .ant-table-cell-scrollbar {
    box-shadow: none !important;
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    background: $highContrast !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0.5rem !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0.5rem !important;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table,
  .ant-table.ant-table-bordered > .ant-table-container {
    border: none !important;
  }
  .ant-table-thead > tr > th {
    margin: 10px !important;
    background-color: #515255 !important; /*primeira linha*/
    border-bottom: none !important;
    color: $highContrast !important;
    text-align: center;
    :last-child {
      text-align: left;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 0 !important;
    background: #515255;
    padding: 5px;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .ant-select {
    font-size: $fontDefault !important;
    color: $outfocusText !important;
  }
  .table-row-light {
    background-color: $lowContrast;
  }
  .table-row-dark {
    background-color: $lowContrast;
  }
  .btn-reload-table {
    padding: 5px 15px;
    border-color: $lowContrast !important;
    &:hover {
      border-color: $highContrast !important;
    }
  }

  /* Estilo do Select AntD */
  .ant-select {
    width: 100%;
    height: $fieldHeight;
    color: $outfocusText !important;
    &:focus-visible{
      color: red;
    }

    .ant-select-clear,
    .ant-select-arrow {
      background-color: $lowContrast !important;
      color: $outfocusText !important;
    }

    .ant-select-selector {
      background-color: $lowContrast !important;
      border-color: $lowContrast !important;
      border-radius: $radius !important;

      &.border-danger {
        border-color: $danger !important;
      }
    }

    &.ant-select-focused {
      color: $highContrast !important;

      .ant-select-selector {
        box-shadow: none !important;
        // border: 1px solid $primary !important;
        // box-shadow: 0px 0px 8px -2px #36b4e5cc !important;
      }
    }
  }
  .ant-select-multiple .ant-select-selection-item {
    background:$low;
    border-color: $low;
    color: $outfocusText !important;

    svg{
      color: $outfocusText;
    }
  }
}

/* Estilo dropdown AntD */
.ant-dropdown {
  /* Dropdown Filter AntD */
  .ant-table-filter-dropdown {
    border-radius: $radius;
    background-color: $low;
    > div {
      background-color: $low;
      border-radius: $radius;

      input {
        height: $fieldHeight;
        border-radius: $radius;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-weight: 600;
        font-size: $fontDefault;
        line-height: 1.5;
        background-clip: padding-box;
        background-color: $lowContrast !important;
        border: 1px solid $lowContrast !important;
        color: $outfocusText;
        box-shadow: none !important;
        transition: none;
        scrollbar-width: none; /*Firefox*/

        &:focus {
          outline: none !important;
          color: $highContrast !important;
        }

        &:-webkit-autofill {
          border-width: 8px;
          padding: 5px;
          -webkit-box-shadow: 0 0 0 1000px $lowContrast inset !important;
          box-shadow: 0 0 0 1000px $lowContrast inset !important;
          -webkit-text-fill-color: $outfocusText !important;
          caret-color: $highContrast;

          &:focus {
            -webkit-text-fill-color: $highContrast !important;
          }
        }

        &:-webkit-scrollbar {
          /*WebKit Browsers*/
          width: 0;
          height: 0;
        }
      }

      .ant-space .ant-space-item {
        .ant-btn {
          border-radius: $radius;
          border: 1px solid;
          height: $fieldHeight;
          padding: 5px 20px;
          font-weight: 600;
          line-height: 26px;
          padding: 5px 23px;
          letter-spacing: 0.4px;
          text-align: center;
          user-select: none;
          transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
          color: $highContrast;
          display: flex;
          justify-content: center;
          align-items: center;

          &:focus,
          &:focus:active,
          &:active,
          &:hover {
            outline: none;
            box-shadow: none;
            border: 1px solid;
          }

          &.disabled,
          &:disabled {
            opacity: 0.3;
          }

          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active,
          .show > &.dropdown-toggle {
            box-shadow: none;
          }

          &:not(:disabled):not(.disabled):active:focus,
          &:not(:disabled):not(.disabled).active:focus,
          .show > &.dropdown-toggle:focus {
            box-shadow: none;
          }

          &:focus,
          &:focus:active,
          &:active,
          &:hover,
          &.disabled,
          &:disabled,
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active,
          .show > &.dropdown-toggle,
          &:not(:disabled):not(.disabled):active:focus,
          &:not(:disabled):not(.disabled).active:focus,
          .show > &.dropdown-toggle:focus {
            color: $highContrast;
          }
        }

        .ant-btn-primary {
          background-color: $primary !important;
          border-color: $primary !important;

          &:focus,
          &:focus:active,
          &:active,
          &:hover {
            background-color: $primaryEvent !important;
            border-color: $primaryEvent !important;
          }

          &.disabled,
          &:disabled,
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active,
          .show > &.dropdown-toggle,
          &:not(:disabled):not(.disabled):active:focus,
          &:not(:disabled):not(.disabled).active:focus,
          .show > &.dropdown-toggle:focus {
            background-color: $primary !important;
            border-color: $primary !important;
          }
        }
      }

      .ant-space .ant-space-item:nth-child(2) .ant-btn {
        background-color: transparent !important;
        border-color: $danger !important;
        color: $danger !important;

        &:focus,
        &:focus:active,
        &:active,
        &:hover {
          background-color: $dangerEvent !important;
          border-color: $dangerEvent !important;
          color: $highContrast !important;
        }

        &.disabled,
        &:disabled,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        .show > &.dropdown-toggle:focus {
          background-color: transparent !important;
          border-color: $danger !important;
        }
      }

      .ant-space .ant-space-item:nth-child(3) .ant-btn {
        border: none;
        color: $primary;

        &:focus,
        &:focus:active,
        &:active,
        &:hover {
          color: $primaryEvent !important;
          text-decoration: underline;
        }
      }
    }
  }

  /* Dropdown Menu AntD */
  .ant-dropdown-menu {
    background-color: $low !important;
    border-color: $low !important;
    border-radius: $radius !important;
    box-shadow: 10px 0px 50px 0px #00000073 !important;

    li {
      color: $textLow !important;

      &:hover {
        background-color: $tableContrast !important;
      }
    }
  }
}

/* Style do Select AntD */
.ant-select {
  width: 100%;
  height: $fieldHeight;
  color: $outfocusText !important;

  .ant-select-clear,
  .ant-select-arrow {
    background-color: $lowContrast !important;
    color: $outfocusText !important;
  }

  .ant-select-selector {
    background-color: $lowContrast !important;
    border-color: $lowContrast !important;
    border-radius: $radius !important;

    .ant-select-selection-item {
      font-size: $fontDefault;
      font-weight: 600;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    }
  }

  &.ant-select-focused {
    color: $highContrast !important;

    .ant-select-selector {
      box-shadow: none !important;
      // border: 1px solid $primary !important;
      // box-shadow: 0px 0px 8px -2px #36b4e5cc !important;
    }
  }
}
.ant-select-dropdown {
  background-color: $lowContrast !important;
  border-radius: $radius !important;
  box-shadow: 10px 0px 50px 0px #00000073 !important;

  .ant-select-item {
    color: $highContrast !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) 
  .ant-select-item-option-state{
    color: $textLow;
  }

  .rc-virtual-list-holder-inner {
    .ant-select-item-option-active {
      background-color: $tableContrast !important;
    }
    .ant-select-item-option-selected {
      background-color: #323335 !important;
    }
  }
}

/* Style do Date Picker do AntD */
.ant-picker {
  background-color: $lowContrast !important;
  border-color: $lowContrast !important;
  border-radius: $radius !important;
  box-shadow: none !important;
  width: 100%;
  height: 32px;
  padding-left: 0;

  svg {
    color: $outfocusText !important;
  }

  .ant-picker-active-bar {
    background-color: $primary !important;
  }

  .ant-picker-clear {
    background-color: $lowContrast !important;
  }

  .ant-picker-input {
    input {
      color: $outfocusText !important;
      font-size: $fontDefault;
    }

    &.ant-picker-input-active input {
      color: $highContrast !important;
    }
  }
}
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-footer .ant-picker-ranges {
  border-top: 1px solid $tableContrast !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: $highContrast !important;
  &:hover {
    background: $low !important;
  }
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: $primary !important;
}

.ant-picker-dropdown {
  .ant-picker-range-arrow::after {
    border-color: $lowContrast !important;
  }

  .ant-picker-panel-container {
    background-color: $lowContrast !important;
    border-radius: $radius !important;
    box-shadow: 10px 0px 50px 0px #00000073 !important;

    .ant-picker-panel {
      border-color: $lowContrast !important;
    }
    .ant-picker-time-panel-column:not(:first-child) {
      border-left: 1px solid $tableContrast;
    }

    .ant-picker-header {
      button {
        color: $outfocusText !important;

        &:hover {
          color: $highContrast !important;
        }
      }

      .ant-picker-header-view {
        color: $highContrast;

        button {
          color: $highContrast !important;

          &:hover {
            color: $primary !important;
          }
        }
      }
    }

    .ant-picker-body {
      thead tr th {
        color: $highContrast !important;
        font-weight: 500 !important;
        text-decoration: underline !important;
      }

      .ant-picker-cell {
        color: $outfocusText !important;

        &:hover .ant-picker-cell-inner {
          background-color: $low !important;
        }

        &.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
          background-color: $primaryEvent;

          &::before {
            border-color: $primaryEvent;
          }
        }

        &.ant-picker-cell-today .ant-picker-cell-inner::before {
          border-color: $primary;
        }

        &.ant-picker-cell-range-start.ant-picker-cell-in-view .ant-picker-cell-inner,
        &.ant-picker-cell-range-end.ant-picker-cell-in-view .ant-picker-cell-inner {
          background-color: $primary !important;
        }

        &.ant-picker-cell-range-start.ant-picker-cell-in-view::before,
        &.ant-picker-cell-range-end.ant-picker-cell-in-view::before {
          background-color: $low;
        }

        &.ant-picker-cell-in-view.ant-picker-cell-in-range {
          &.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
          &.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
            background-color: $lowContrast;
          }

          &::before {
            background-color: $low;
          }
        }
      }

      .ant-picker-cell-in-view {
        color: $highContrast !important;
      }
    }

    .ant-picker-footer .ant-picker-today-btn {
      padding: 2.5px 8px;
      border-radius: 2px;
      font-size: $fontDefault;
    }

    .ant-picker-footer .ant-picker-ranges .ant-picker-preset .ant-tag-blue,
    .ant-picker-footer .ant-picker-today-btn {
      background-color: $primary !important;
      border-color: $primary !important;
      color: $highContrast !important;

      &:hover {
        background-color: $primaryEvent !important;
        border-color: $primaryEvent !important;
      }
    }

    .ant-picker-footer .ant-picker-ranges {
      border-top: 1px solid #f0f0f0;

      .ant-btn-primary[disabled] {
        background: rgba(54, 180, 229, 0.2) !important;
        border-color: rgba(54, 180, 229, 0.1) !important;
        color: $outfocusText;
        &:hover {
          background: rgba(54, 180, 229, 0.2) !important;
          border-color: rgba(54, 180, 229, 0.2);
        }
      }
      .ant-btn-primary {
        background: $primary !important;
        border-color: $primary;
        color: $highContrast;
        &:hover {
          background: $primaryEvent !important;
          border-color: $primaryEvent;
        }
      }
      .ant-picker-ok {
        float: none;
        margin-left: 5px;
      }
    }
  }
}
