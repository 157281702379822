.vacancy-register-row {
  label {
    color: #fff;
  }

  #editor-container {
    height: 375px;
  }

}
