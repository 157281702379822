.error-page .error403{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8.5%;
}
.error403 .forbidden{
    height: 50vh;
}
.error-page h2{
    color: white;
}
.error-page div{
    display: flex;
    justify-content: center;
}
.error-page .notFound{
    height: 85vh;
}

