.container-vacancy {
  margin-top: 160px;

  h2:nth-child(2) {
    margin-bottom: 100px;
  }

  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .text-highlight {
    font-size: 28px;
    transition: all 0.5s;
  }

  .text-highlight:hover {
    cursor: pointer;
    color: #2c9bc7;
    margin-right: -60px;
  }
}

@media (max-width: 590px) {
  .container-vacancy {
    .text-highlight:hover {
      margin-right: -15px;
    }
  }
}

// Responsividade
@media (max-width: 1000px) {
  .container-vacancy {
    h2:nth-child(2) {
      margin-bottom: 80px;
    }
  }
}
@media (max-width: 767px) {
  .container-vacancy {
    margin-top: 100px;

    h2:nth-child(2) {
      margin-bottom: 50px;
    }
  }
}
