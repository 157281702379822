.Collapsible {
  .Collapsible__trigger {
    color: #fff;
    display: flex;
    justify-content: space-between;
    height: 24px;
    cursor: pointer;
    font-size: 18px;
    border-bottom: 1px solid #b0b0b091;
    line-height: 18px;
    margin-bottom: 16px;

    svg {
      transition: 0.1s;
    }
  }

  .Collapsible__contentOuter {
    border-left: 1px solid rgba(176, 176, 176, 0.57);
    padding-left: 16px;
  }

  .arrow-open svg{
    transform: rotate(-90deg);
  }
}
