.banner {
  margin-top: -100px;

  &::before {
    bottom: -114px;
    content: "";
    background: linear-gradient(-180deg, rgba(234, 237, 237, 0), #fff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00EAEDED', endColorstr='#EAEDED', GradientType=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00EAEDED', endColorstr='#EAEDED',GradientType=0)";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 350px;
  }

  img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: inherit;
    z-index: 0;
  }

  .banner-title {
    flex-direction: column;
    margin-top: -450px;
  }
}

.hot-numbers {
  margin: 250px auto 0px auto;
  position: relative;

  strong {
    font-size: 26px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    font-weight: 700;
  }
}

.services-cards {
  margin: 200px auto 0px auto;

  .card {
    padding: 25px 0 0 0;
    box-shadow: 0 0 20px 2px #3c3c3c57;

    button {
      width: 100%;
    }

    p {
      font-size: 15px;
      font-weight: 500;
      text-align: justify;
      height: 150px;
    }

    h5 {
      font-size: 18px;
      font-weight: 700;
      height: 45px;
    }

    a {
      font-weight: 700 !important;
    }
  }
}

@media (min-width: 1885px) {
  .banner {
    .banner-title {
      margin-top: -35%;
      margin-bottom: 20%;
    }

    &::before {
      display: none;
    }
  }
}
@media (max-width: 1200px) {
  .banner {
    .banner-title {
      margin-top: -350px;
    }
  }

  .services-cards {
    margin: 80px auto 0px auto;

    .card {
      p {
        height: 190px;
      }
    }
  }

  .hot-numbers {
    margin: 200px auto 0px auto;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .banner .banner-title {
    margin-top: -250px;
  }
}

@media (max-width: 768px) {
  .banner {
    margin-top: 0px;

    &::before {
      bottom: 280px;
      left: 0;
      right: 0;
      top: 90px;
    }

    .banner-title {
      margin-top: -250px;
    }

    h1 {
      font-size: 32px;
      line-height: 25px;
    }
  }
}

@media (max-width: 600px) {
  .banner {
    &::before {
      bottom: 280px;
      left: 0;
      right: 0;
      top: 90px;
    }

    .banner-title {
      flex-direction: column;
      margin-top: -150px;
    }

    h1 {
      font-size: 24px;
      line-height: 20px;
    }
  }
}

@media (max-width: 500px) {
  .banner {
    margin-top: 0px;

    &::before {
      bottom: 280px;
      left: 0;
      right: 0;
      top: 90px;
    }

    .banner-title {
      flex-direction: column;
      margin-top: -90px;
    }

    h1 {
      font-size: 20px;
      line-height: 14px;
    }
  }

  .services-cards {
    margin-top: -30px;

    .card {
      p {
        height: 130px;
      }
    }
  }

  .hot-numbers {
    margin-top: 50px;
  }
}
